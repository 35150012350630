import React, { useState, useEffect } from "react";
// Customizable Area Start
import {
    StyleSheet,
    Text,
    TouchableOpacity,
    FlatList,
    View,
    TextInput,
    ActivityIndicator,
    Image,
    ScrollView,
} from "react-native";
import DesktopHeader from "./DesktopHeader";
import DesktopMenue from "./DesktopMenue";
import scale, { verticalScale } from "../../../framework/src/Scale";
import { getAxiosAPI, getErrorMessage } from "../../../framework/src/Blocks/AxiosAPIBlock";
import { SortData } from "../../../framework/src/Utilities";
import { Popover } from "@material-ui/core";
import {RygCircle, RvgStatus} from "../../../components/src/RygCircle"
// import Card from 


export default function DesktopSurgeryDetails({ navigation }: { navigation: any }) {
    const [data, setData] = useState([]);
    const [query, setQuery] = useState("");
    const order = JSON.parse(localStorage.getItem('order') || '{}');
    const role_id = localStorage.getItem('role') || ''

    const [offset, setOffset] = useState(0);
    const [loader, setLoader] = useState(false);

    const [message, setMessage] = useState("");
    const [popup, setPopup] = useState(false)


    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClick = (e:any) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    useEffect(() => {
        getListOfMaterials();
    }, [offset]);

    const getListOfMaterials = async (search: any = '') => {
        setLoader(true)
        let url = `/bx_block_materials/materials?brand_id=${order.manfacturer_id}&category_id=${order.category_id}&limit=12&offset=0&search=${search}`

        await getAxiosAPI(url).then((res: any) => {
            if(res.data.errors){
                setData([])
                setLoader(false)
            } else {
                setLoader(false)
                setData(res.data.data.sort((a: any,b: any) => SortData(a.attributes.name.toLowerCase(), b.attributes.name.toLowerCase())))
            }
        })
    }
    const handleSearch = (text : any) => {
        setQuery(text)
        getListOfMaterials(text)
    };

    const navigateTo = (item: any) => {
        localStorage.setItem('selectedMaterial', JSON.stringify(item));
        navigation.navigate('MaterialDetails')
    }

    const ListEmptyView = () => {
        return (
            <View>
                <Text style={styles.heading2}>No Record Found</Text>
            </View>
        )}

    const PopoverTest = ()=>{
        return <Popover
            data-testId = "PopoverTest"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={

                handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            PaperProps={{
                style: {
                    position: 'absolute',
                    top: 10,
                    left: 0,
                    backgroundColor: '#fff',
                    padding: 10,
                    borderRadius: 5,
                    marginTop: 10,
                    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px -5px'
                },
            }}
            onClick={(e) => e.stopPropagation()}
        >
            <View style={styles.popoverContent} >
                <RvgStatus category={{ name: "Recomended", bgColor: "#0FFF50" }} />
                <RvgStatus category={{ name: "Permisible", bgColor: "#FFFF00" }} />
                <RvgStatus category={{ name: "Not Recomended", bgColor: "#FF0000" }} />
            </View>
        </Popover>
    }

    const getColorCode = (color: string) => {
        switch (color) {
            case 'red':
                return '#FF0000';
            case 'yellow':
                return '#FFFF00';
            case 'green':
                return '#0FFF50';
            default:
                return '#000000';
        }
    }

    return (
        <View style={styles.desktopContainer}>
            {PopoverTest()}
            <DesktopHeader heading={'Materials List'} navigation={navigation} />
            <View style={styles.bottomDesktopCard}>
                <DesktopMenue navigation={navigation} />
                <View style={{ flex: 0.85}}>
                    <View style={styles.outerCard}>
                        <View style={{ flexDirection: 'row' }}>
                            <View style={styles.searchBox}>
                                <TextInput
                                    style={styles.input}
                                    placeholder="Search "
                                    autoCapitalize="none"
                                    autoCorrect={false}
                                    clearButtonMode="always"
                                    placeholderTextColor="#939ca3"
                                    value={query}
                                    onChangeText={queryText => handleSearch(queryText)}
                                />
                                <TouchableOpacity onPress={() => setQuery('')}>
                                    <img src={query === '' ? require("../assets/search.png") : require("../assets/cross.png")} style={{ height: 30, width: 30, paddingRight: 15, paddingTop: 10 }} />
                                </TouchableOpacity>

                            </View>

                        </View>
                        {loader ? <ActivityIndicator
                                style={{ margin: 'auto', height: 100, width: 100, }}
                                size="large" color="#4e3e71" />
                            :
                            <View style={styles.card}>
                                <ScrollView style={{ flex: 1 }}>
                                    {
                                        (query.length > 0 && data.length > 0) ?
                                            <View style={styles.add}>
                                                <Text style={styles.resultText}>{`Showing result "${query}"`}</Text>
                                            </View>
                                            :
                                            (query.length > 0 && data.length === 0) ?
                                                <View style={styles.add}>
                                                    <Text style={styles.resultText}>Searched Material not in the catlouge
                                                    </Text>
                                                </View> : null
                                    }

                                    <FlatList
                                        data={data}
                                        horizontal={false}
                                        ListEmptyComponent={ListEmptyView()}
                                        numColumns={6}
                                        keyExtractor={(item: any) => item.id}
                                        data-testId = "flatList"
                                        renderItem={({ item }) => (
                                            <View>
                                                <TouchableOpacity data-testId="flatListItem" onPress={(e) => navigateTo(item)}>
                                                    <View style={styles.listm}>
                                                        <TouchableOpacity
                                                            style={styles.rygBtn}
                                                            activeOpacity={0.7}
                                                            data-testId="rygBtn"
                                                            onPress={handleClick}
                                                        >
                                                            <RygCircle backgroundColor={getColorCode(item.attributes.color_value)} />
                                                        </TouchableOpacity>
                                                        {
                                                            item.attributes.image ? <img src={item.attributes.image} style={{ height: 120, width: 120, borderRadius: 12 }} /> :
                                                                <img src={require("../assets/dummyMaterial.png")} style={{ height: 120, width: 120, borderRadius: 12 }} />}

                                                        <View>
                                                            <Text style={styles.name} numberOfLines={2}>{item.attributes.name}</Text>
                                                            <View style={styles.priceView}>
                                                                <Text style={styles.discount_price}>{item.attributes.discount_price ? "$"+item.attributes.discount_price : "$0.00"}</Text>
                                                                <Text style={styles.price}>{item.attributes.price ? "$"+item.attributes.price : "$0.00"}</Text>
                                                            </View>
                                                        </View>
                                                    </View>
                                                </TouchableOpacity>

                                                {/* : null
                                                                        } */}
                                            </View>

                                        )}
                                    />
                                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-around', marginHorizontal: 200}}>
                                        {offset < 1 ? null : <TouchableOpacity onPress={() => setOffset(offset-10)}><Text style={{ color: '#4e3e71', fontWeight: 'bold'}}>{"< Prev"}</Text></TouchableOpacity>}
                                        {data.length < 12 ? null :  <TouchableOpacity onPress={() => setOffset(offset+10)}><Text style={{ color: '#4e3e71', fontWeight: 'bold'}}>{"Next >"}</Text></TouchableOpacity>}
                                    </View>
                                </ScrollView>

                            </View>
                        }
                    </View>
                </View>
            </View>

        </View>
    )
}

const styles = StyleSheet.create({
    desktopContainer: {
        flex: 1,
        flexDirection: 'column'
    },
    size: { color: '#b2bac6', fontSize: 10, paddingBottom: 5 },
    priceView: {flex: 1, flexDirection: 'row', justifyContent: 'space-between'},
    heading2: {
        marginVertical: 100,
        marginHorizontal: 'auto',
        color: '#b2bac6',
        fontSize: 24,
        fontWeight: 'bold',
        flexDirection : 'row',
        justifyContent: 'center'
    },
    name: {
        color: '#4e3e71',
        paddingVertical: 5,
        fontSize: 12,
        width: scale(40)
    },
    price: {
        color: '#b2bac6',
        paddingVertical: 5,
        fontSize: 13,
        textDecorationLine: 'line-through',
        width: scale(40)
    },
    discount_price: {
        color: '#4e3e71',
        paddingVertical: 5,
        fontSize: 14,
        width: scale(40)
    },
    listm: {
        overflow: 'hidden',
        shadowColor: '#b2bac6',
        height:220,
        width: 160,
        shadowRadius: 5,
        shadowOpacity: 1,
        borderRadius: 12,
        padding: 16,
        borderColor: '#f2f4f5',
        borderWidth: 1,
        marginHorizontal: 8,
        marginVertical: 8,
        position : "relative"
    },
    rygBtn : {
        height : 20,
        width : 20,
        borderColor : "#ccc",
        borderWidth: 1,
        position : "absolute",
        top : -1,
        left : -1,
        display : "flex",
        justifyContent : "center",
        alignItems : "center",
    },
    button: {
        borderRadius: 12,
        paddingVertical: 15,
        paddingHorizontal: 20,
        marginVertical: 10,
        backgroundColor: '#4e3e71',
        // width: '20%',
        marginHorizontal: 10
    },
    buttonText: {
        color: '#e8fafe',
        fontSize: 17,
        textAlign: 'center',
    },
    button0: {
        borderRadius: 12,
        paddingVertical: 15,
        paddingHorizontal: 20,
        marginVertical: 10,
        backgroundColor: '#aa9cc9',
        // width: '20%',
        marginHorizontal: 10
    },
    buttonText0: {
        color: '#e8fafe',
        fontSize: 17,
        textAlign: 'center',
    },
    listItem: {
        height: 88,
        width: 360,
        marginHorizontal: 16,
        borderColor: '#e5e5e5',
        borderWidth: 1,
        borderRadius: 12,
        marginVertical: 5,
        padding: 20,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    listItem_active: {
        height: 88,
        width: 360,
        marginHorizontal: 16,
        borderColor: '#4e3e71',
        borderWidth: 1,
        borderRadius: 12,
        marginVertical: 5,
        padding: 20,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    role: {
        color: '#b2bac6',
        paddingHorizontal: 11,
        width: scale(50),

    },
    names: {
        color: '#4e3e71',
        fontSize: 15,
        paddingHorizontal: 11,
        width: scale(60),

    },

    add: {
        flexDirection: 'row',
        justifyContent: "flex-start"
    },
    resultText: {
        paddingVertical: 10,
        fontSize: 18

    },
    card: {
        flex: 1,
        marginTop: 10,
        borderRadius: 6,
        elevation: 3,
        backgroundColor: '#fff',
        marginVertical: 6,
    },
    filterIcon: {
        height: 48,
        width: 48,
        borderRadius: 24,
        padding: 8,
        borderWidth: 1,
        borderColor: '#4e3e71',
        marginVertical: 'auto',
        marginHorizontal: 'auto'
    },
    bottomDesktopCard: {
        flex: 1,
        flexDirection: 'row'
    },
    picker: {
        height: 50,
        width: 160,
        color: 'white',
        paddingHorizontal: 15,
        backgroundColor: '#4e3e71',
        borderRadius: 50,
    },
    searchBox: {
        flexDirection: 'row',
        marginVertical: 20,
        borderWidth: 1,
        borderColor: '#8a8a8a',
        borderRadius: 50
    },
    input: {
        borderWidth: 0,
        flex: 1,
        paddingLeft: 10,
        height: 48,
        color: '#4e3e71',
        fontSize: 16,
        paddingStart: 20,
        width: 950
    },
    outerCard: {
        flex: 1,
        elevation: 3,
        backgroundColor: 'white',
        borderWidth: 1,
        borderRadius: 12,
        borderColor: '#7f7f7f',
        padding: 10,
        margin: 15,
        flexDirection: 'column'

    },
    popoverContent: {
        padding: 5,
    },

})